import { customAttribute, inject } from 'aurelia-framework';
import { BindingEngine }           from 'aurelia-binding';
import 'assets/js/plugins/forms/styling/uniform.min';

@inject(Element, BindingEngine)
@customAttribute('styled-input')
export class StyledInput {

    /**
     * Constructor
     *
     * @param element
     * @param bindingEngine
     */
    constructor(element, bindingEngine) {
        this.element       = element;
        this.bindingEngine = bindingEngine;
    }

    /**
     * Handles binding
     */
    bind() {
        $(this.element).uniform();

        //console.log(this.element);

        //this.subscription = this.bindingEngine.propertyObserver(this.element, 'checked').subscribe(() => console.log(this.element.checked));
    }

    /**
     * Handles unbinding
     */
    unbind() {
        $.uniform.restore(this.element);

        //this.subscription.dispose();
    }
}
