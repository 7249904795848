import { bindable, customAttribute, inject } from 'aurelia-framework';

@inject(Element)
@customAttribute('select2')
export class Select2 {

    @bindable settings;

    /**
     * Constructor
     *
     * @param element
     */
    constructor(element) {
        this.element = element;
    }

    /**
     * Handles binding
     */
    bind() {
        $(this.element)
            .select2(this.settings)
            .on('change', (event) => {
                // don't propagate endlessly
                if (event.originalEvent) {
                    return;
                }
                // dispatch to raw select within the custom element
                let notice = new CustomEvent('change', {
                    bubbles: false,
                });

                $(this.element)[0].dispatchEvent(notice);
            });
    }

    /**
     * Handles unbinding
     */
    unbind() {
        $(this.element).select2('destroy');
    }
}
