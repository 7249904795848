import { customAttribute, inject } from 'aurelia-framework';

@inject(Element)
@customAttribute('bootstrap-popover')
export class BootstrapPopover {

    /**
     * Constructor
     *
     * @param element
     */
    constructor(element) {
        this.element = element;
    }

    /**
     * Handles binding
     */
    bind() {
        if (this.element.getAttribute('data-toggle') === 'popover') {
            $(this.element).popover();
        }
    }

    /**
     * Handles unbinding
     */
    unbind() {
        if (this.element.getAttribute('data-toggle') === 'popover') {
            $(this.element).popover('dispose');
        }
    }

}
