import { customAttribute, inject } from 'aurelia-framework';

@inject(Element)
@customAttribute('bootstrap-tooltip')
export class BootstrapTooltip {

    /**
     * Constructor
     *
     * @param element
     */
    constructor(element) {
        this.element = element;
    }

    /**
     * Handles binding
     */
    bind() {
        $(this.element).tooltip({
            trigger: 'hover',
        });
    }

    /**
     * Handles unbinding
     */
    unbind() {
        $(this.element).tooltip('dispose');
    }

}
