import { bindable, customAttribute, inject } from 'aurelia-framework';

@inject(Element)
@customAttribute('initials-avatar')
export class InitialsAvatar {

    @bindable name;
    @bindable size;

    colours = [
        '#1abc9c',
        '#2ecc71',
        '#3498db',
        '#9b59b6',
        '#34495e',
        '#16a085',
        '#27ae60',
        '#2980b9',
        '#8e44ad',
        '#2c3e50',
        '#f1c40f',
        '#e67e22',
        '#e74c3c',
        '#95a5a6',
        '#f39c12',
        '#d35400',
        '#c0392b',
        '#bdc3c7',
        '#7f8c8d',
    ];

    /**
     * Constructor
     *
     * @param element
     */
    constructor(element) {
        this.element = element;
    }

    /**
     * Handles binding
     */
    bind() {
        this.element.src = this.generateAvatar(this.name, this.size);
    }

    /**
     * Generates an avatar based on user name initials
     * TODO: This needs to be refactored!!!
     *
     * @param name
     * @param size
     *
     * @returns {string}
     */
    generateAvatar(name, size) {
        let nameSplit = String(name).toUpperCase().split(' ');
        let initials;
        let charIndex;
        let colourIndex;
        let canvas;
        let context;
        let dataUri;

        if (nameSplit.length === 1) {
            initials = nameSplit[0] ? nameSplit[0].charAt(0) : '?';
        } else {
            initials = nameSplit[0].charAt(0) + nameSplit[1].charAt(0);
        }

        if (window.devicePixelRatio) {
            size = (size * window.devicePixelRatio);
        }

        charIndex     = (initials === '?' ? 72 : initials.charCodeAt(0)) - 64;
        colourIndex   = charIndex % 20;
        canvas        = document.createElement('canvas');
        canvas.width  = size;
        canvas.height = size;
        context       = canvas.getContext('2d');

        context.fillStyle = this.colours[colourIndex - 1];
        context.fillRect(0, 0, canvas.width, canvas.height);
        context.font      = Math.round(canvas.width / 2) + 'px Roboto';
        context.textAlign = 'center';
        context.fillStyle = '#FFF';
        context.fillText(initials, size / 2, size / 1.45);

        dataUri = canvas.toDataURL();
        canvas  = null;

        return dataUri;
    }

}
