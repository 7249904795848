import { bindable, customAttribute, inject } from 'aurelia-framework';
import autosize                              from 'autosize';

@inject(Element)
@customAttribute('autosized')
export class Autosized {

    @bindable settings;

    /**
     * Constructor
     *
     * @param element
     */
    constructor(element) {
        this.element = element;
    }

    /**
     * Handles binding
     */
    bind() {
        let element = $(this.element);

        autosize(element);
    }

    /**
     * Handles unbinding
     */
    unbind() {
        let element = $(this.element);

        autosize.destroy(element);
    }
}
