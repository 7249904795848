import { PLATFORM } from 'aurelia-pal';

export function configure(config) {
    config.globalResources(
        [
            PLATFORM.moduleName('./bootstrap-popover'),
            PLATFORM.moduleName('./bootstrap-tooltip'),
            PLATFORM.moduleName('./initials-avatar'),
            PLATFORM.moduleName('./legit-ripple'),
        ],
    );
}
