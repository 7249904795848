import { customAttribute, inject } from 'aurelia-framework';
import 'legit-ripple/dist/ripple.min';

@inject(Element)
@customAttribute('legit-ripple')
export class LegitRipple {

    /**
     * Constructor
     *
     * @param element
     */
    constructor(element) {
        this.element = element;
    }

    /**
     * Handles binding
     */
    bind() {
        $(this.element).ripple({
            dragging:  false,
            adaptPos:  false,
            scaleMode: false,
        });
    }

    /**
     * Handles unbinding
     */
    unbind() {
        $(this.element).ripple({unbind: true});
    }
}
